<template>
  <div class="view pa24">
    选择状态：
    <el-radio
      class="mr10"
      style="margin: 0"
      v-for="(item, index) in radioArr"
      :key="index"
      v-model="radioValue"
      :label="item.value"
      border
      >{{ item.text }}</el-radio
    >
    <el-button type="primary" @click="queryVerifyPageBusinessCard"
      >搜索</el-button
    >
    <div style="float: right">
      <el-button type="primary" @click="batchTejectAuditing"
        >批量驳回</el-button
      >
      <el-button type="primary" class="ml20" @click="batchPassAuditing"
        >批量通过</el-button
      >
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSelectionChange="handleSelectionChange"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      clearName = 'demandProtuctsCheck'
      itemKey='verify'
      :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="55" />
        <el-table-column
          type="index"
          align="center"
          label="序号"
          show-overflow-tooltip
        />
        <el-table-column prop="createTime" align="center" label="创建时间" >
           <template slot-scope="scope">
             {{scope.row.createTime | getDataTimeSec}}
           </template>
        </el-table-column>
        <el-table-column prop="companyName" align="center" label="公司名称" />
        <el-table-column prop="position" align="center" label="职位" />
        <el-table-column prop="name" align="center" label="名片使用人" />
        <el-table-column align="center" prop="verify" label="状态">
          <!--                    审核状态 0待审核 1 审核通过 2 审核不通过-->
          <template slot-scope="scope">
            {{
              scope.row.verify == 0
                ? "待审核"
                : scope.row.verify == 1
                ? "已通过"
                : scope.row.verify == 2 && "已驳回"
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="170">
          <template slot-scope="scope">
            <el-button
              @click="rejectAuditing(scope.row)"
              type="text"
              size="small"
              style="color: #fd634e"
              v-if="scope.row.verify == 0"
            >
              {{ scope.row.verify == 0 ? "驳回" : "---" }}
            </el-button>
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="verifyBusinessCard(scope.row)"
              v-if="scope.row.verify == 0"
              icon="el-icon-info"
              icon-color="red"
              title="确认通过审核吗？">
              <el-button slot="reference" type="text" class="ml10" size="small">
                {{ scope.row.verify == 0 && "通过" }}
              </el-button>
            </customPopconfirm>
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="offBusinessCard(scope.row)"
              v-if="scope.row.verify == 1"
              icon="el-icon-info"
              icon-color="red"
              title="确认删除吗？">
              <el-button type="text" slot="reference" size="small">
                删除
              </el-button>
            </customPopconfirm>
            <el-button type="text" size="small" v-if="scope.row.verify == 2">
              {{ scope.row.reasonNote }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="驳回原因"
      width="400px"
      ref="form"
      :modal-append-to-body="false"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="name">
          <el-input v-model="ruleForm.name" type="textarea" rows="2"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btncss" @click="submitData()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getDataTimeSec } from "@/utils";
import {
  queryVerifyPageBusinessCard,
  verifyBusinessCard,
  offBusinessCard,
  batchVerifyBusinessCard,
} from "@/api/addedServices";
export default {
  data() {
    return {
      radioValue: "",
      radioArr: [
        { text: "待审核&已通过", value: "" },
        { text: "待审核", value: "0" },
        { text: "已通过", value: "1" },
        { text: "已驳回", value: "2" },
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      dialogFormVisible: false,
      form: {},
      ruleForm: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入驳回原因", trigger: "blur" }],
      },
      multipleSelection: [],
      isMultipleSelection: false,
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.queryVerifyPageBusinessCard();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryVerifyPageBusinessCard();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVerifyPageBusinessCard();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async queryVerifyPageBusinessCard() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        verify: this.radioValue,
        isPublic: 1,
      };
      data.isPublic = data.verify == 2 ? 0 : 1;
      try {
        this.loading = true;
        const result = await queryVerifyPageBusinessCard(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    /**@method 通过审核 */
    async verifyBusinessCard(row) {
      let data = JSON.parse(JSON.stringify(row));
      data.verify = 1;
      await verifyBusinessCard(data);
      await this.queryVerifyPageBusinessCard();
    },
    /**@method 驳回 */
    rejectAuditing(row) {
      this.dialogFormVisible = true;
      this.form = row;
    },
    async submitData() {
      if (this.isMultipleSelection) {
        let data = {
          cards: this.multipleSelection.map((item) => item.cardId),
          verify: 2,
          isPublic: 0,
          reasonNote: this.ruleForm.name,
        };
        await batchVerifyBusinessCard(data);
        this.dialogFormVisible = false;
        this.queryVerifyPageBusinessCard();
      } else {
        let data = JSON.parse(JSON.stringify(this.form));
        data.verify = 2;
        data.reasonNote = this.ruleForm.name;
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            try {
              await verifyBusinessCard(data);
              this.dialogFormVisible = false;
              await this.queryVerifyPageBusinessCard();
            } catch (error) {
              console.log(error);
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    /**@method 删除 */
    async offBusinessCard(row) {
      let data = JSON.parse(JSON.stringify(row));
      data.verify = 1;
      try {
        await offBusinessCard(data);
        this.queryVerifyPageBusinessCard();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 批量通过 */
    async batchPassAuditing() {
      if (this.multipleSelection.length != 0) {
        let data = {
          verify: 1,
          cards: this.multipleSelection.map((item) => item.cardId),
        };
        await batchVerifyBusinessCard(data);
        this.queryVerifyPageBusinessCard();
      } else {
        this.$message({
          message: "请选择需求",
          type: "warning",
        });
      }
    },
    /**@method 批量驳回 */
    batchTejectAuditing() {
      if (this.multipleSelection.length != 0) {
        
        this.isMultipleSelection = true;
        this.dialogFormVisible = true;
      } else {
        this.$message({
          message: "请选择需求",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>